<template>
  <b-card-code>
    <b-card-body class="text-center">
        <h2 class="text-primary">
            Edit Dimension
        </h2>
        <b-card-text class="mb-2">
            <span>Quisque lacinia mauris a consectetur malesuada.<br>
                Integer ultrices dolor non tempus facilisis. </span>
        </b-card-text>
    </b-card-body>
    <b-form>
        <b-row>
            <b-col
            cols="12"
            offset="1"
            md="5"
            >
            <b-form-group
                label="Change parent"
            >
                <v-select
                    id="parent"
                    v-model="selectedParent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :selectable="option => ! option.value.includes('select_parent')"
                    :options="ParentName"
                    label="text"
                />
            </b-form-group>
            </b-col>

            <b-col
            cols="12"
            md="5"
            >
            <b-form-group
                label="Other option"
                >
                <v-select
                    id="other-option"
                    v-model="selectedOtherOption"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :selectable="option => ! option.value.includes('select_otherOption')"
                    :options="otherOptionName"
                    label="text"
                />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="1"
            md="10"
            >
                <label for="textarea-default">Other option</label>
                <b-form-group>
                  <b-form-input
                      placeholder="Insert Value"
                  />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="2"
            md="8"
            >
                <b-card-text class="my-1">
                    File Name: <strong>Business Dimensions.xls</strong> 
                    |
                    File Size: <strong>12 KB</strong>
                </b-card-text>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="2"
            md="8"
            >
                <b-progress
                    :max="max"
                    class="progress-bar-success"
                >
                    <b-progress-bar
                    :value="value"
                    :label="`${((value / max) * 100).toFixed(2)}%`"
                    
                    />
                </b-progress>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="2"
            md="8"
            >
                <b-card-text class="my-1">
                    <feather-icon
                        icon="CheckIcon"
                        size="20"
                        class="mr-0 mr-sm-50"
                    />
                    Upload-2
                </b-card-text>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="2"
            md="4"
            >
                <b-card-text class="my-1">
                    <feather-icon
                        icon="XIcon"
                        size="20"
                        class="mr-0 mr-sm-50"
                    />
                    Failed-1
                </b-card-text>
            </b-col>
            <b-col
            cols="12"
            md="4"
            class="d-flex justify-content-end"
            >
                <b-card-text class="my-1">
                    Edit
                    <feather-icon
                        icon="Edit2Icon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                </b-card-text>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="2"
            md="8"
            >
                <b-card-text class="my-1">
                    Preview
                </b-card-text>
                <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :rtl="direction"
                    :pagination-options="{
                        enabled: true,
                        perPage:pageLength
                    }"
                >
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <span
                    v-if="props.column.field === 'example1'"
                    class="text-nowrap"
                    >
                    <span class="text-nowrap">{{ props.row.example1 }}</span>
                    </span>

                    <span v-else-if="props.column.field === 'example4'">
                    
                        {{ props.row.example4 }}
                    
                    </span>

                    
                    <span v-else-if="props.column.field === 'action'">
                    <span>
                        <feather-icon
                            icon="PlusSquareIcon"
                            class="mr-50"
                        />
                        <feather-icon
                            icon="EyeIcon"
                            class="mr-50"
                        />
                    </span>
                    </span>

                    
                    <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                
                <template
                    slot="pagination-bottom"
                >
                    <div class="d-flex justify-content-center flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                        Showing 1 to 7
                        </span>
                        <span class="text-nowrap"> of 100 entries </span>
                    </div>
                    <div>
                    </div>
                    </div>
                </template>
                </vue-good-table>
            </b-col>
        </b-row>

        <b-row style="padding-top: 1.5rem;">
            <b-col 
                cols="12"
                offset="2"
                md="8"
            >
                <b-button
                    variant="dark"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                    Continue
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                </b-button>
            </b-col>
        </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BButton, BProgress, BProgressBar, BFormFile, BRow, BCol, BFormGroup, BFormInput, BForm, BCardText, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BProgress,
    BProgressBar,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCardText,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      value: 100,
      max: 100,
      selectedParent: 'Select Parent',
      selectedOtherOption: 'Selection Options',
      parentName: [
        
      ],
      otherOptionName: [
        
      ],
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'example1',
        },
        {
          label: 'Description',
          field: 'example2',
        },
        {
          label: 'Currency',
          field: 'example3',
        },
        {
          label: 'Status',
          field: 'example4',
        },
        {
          label: 'Autor',
          field: 'example5',
        },
        {
          label: 'Created',
          field: 'example6',
        },
        {
          label: 'ADD',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/upload')
      .then(res => { this.rows = res.data })
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>